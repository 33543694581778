<template>
    <div class="_wrap">


        <div class="_content">
            <div class="_auto">
                <div class="_bread">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/education' }">馆藏文物</el-breadcrumb-item>
                        <el-breadcrumb-item><span class="_myt">{{ detail.title }}</span></el-breadcrumb-item>
                    </el-breadcrumb>
                </div>


                <div class="_nr">

                    <div class="_cont">
                        <div class="_left">
                            <!-- <img :src="$IMG_URL + detail.cover" alt=""> -->

                            <el-image title="点击查看大图" v-if="detail.cover" class="_img" fit="contain"
                                :src="$IMG_URL + detail.cover" :preview-src-list="srcList">
                            </el-image>

                        </div>

                        <div class="_right">
                            <div class="_h4">{{ detail.title }}</div>
                            <p>年代：{{ detail.times }}</p>
                            <p>具体年代：{{ detail.times_detail }}</p>

                            <p>分类：{{ detail.texture }}</p>
                            <div class="_js">{{ detail.description }}</div>

                            <div class="_btn" @click="downLoad">下载</div>

                            <!-- <a @click="downImg"
                                href="https://www.suitangluoyang.com/file/type_image/image/20231113/b9f61097a6f2d9bc4566bc250d37cc29.webp"
                                download style="display: none;"></a> -->
                        </div>
                    </div>



                </div>
            </div>
        </div>

        <fixIcon />
        <coment ref="comentRef"></coment>
        <mainFooter ref="mainFooterRef"></mainFooter>
    </div>
</template>

<script>
import { getDetail } from "@/api/getData"
export default {
    data() {
        return {
            id: "",
            detail: {},
            srcList: [],
        }
    },
    created() {
        this.getDetail()
    },
    async mounted() {


    },
    methods: {
        downLoad() {
            this.$confirm('确定要下载此图片吗', '消息', {
                customClass: 'message-logout',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.downImg(this.$IMG_URL + this.detail.cover)

            }).catch(() => {

            });
        },


        // 下载
        downImg(imgUrl) {
            var img = imgUrl
            var alink = document.createElement("a");
            alink.href = img;
            console.log(alink)
            alink.download = "大众日报";
            alink.click();
            document.body.removeChild(link)
        },



        getDetail() {
            const params = {
                id: this.$route.query.id
            }
            getDetail(params).then(res => {
                this.detail = res.data
                this.srcList.push(this.$IMG_URL + this.detail.cover)
                // this.srcList.push("https://www.suitangluoyang.com/file/type_image/image/20231113/b9f61097a6f2d9bc4566bc250d37cc29.webp")

            })
        }




    }
}
</script>

<style lang="scss" scoped>
@import "@/common/mixin.scss";

._wrap
{
    width: 100%;
    overflow: hidden;
    background-color: #FDFAF6;
}



._content
{
    width: 100%;
    margin-bottom: 100px;
    margin-top: 200px;

    ._auto
    {
        width: 85%;
        margin: 0 auto;

        ._bread
        {
            margin-top: 24px;
        }

        ._nr
        {
            margin-top: 66px;

            ._cont
            {
                display: flex;

                ._left
                {
                    width: 750px;
                    height: 471px;
                    overflow: hidden;
                    border: 1px solid #E5DECD;
                    // cursor: pointer;
                    cursor: zoom-in !important;

                    ._img
                    {
                        width: 100%;
                        height: 100%;
                        // object-fit: cover;
                        object-fit: contain;
                        transition: all .5s;
                    }

                    ._img:hover
                    {
                        transform: scale(1.1);
                    }
                }

                ._right
                {
                    flex: 1;
                    margin-left: 80px;

                    ._h4
                    {
                        color: #8A2415;
                        font-size: 36px;
                        font-weight: bold;

                    }

                    p
                    {
                        font-size: 24px;
                        margin-top: 30px;
                        color: #333;
                    }

                    ._js
                    {
                        font-size: 24px;
                        margin-top: 30px;
                        color: #333;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        /* 设置最大显示行数 */
                        -webkit-box-orient: vertical;
                        text-overflow: ellipsis;
                    }

                    ._btn
                    {
                        width: 152px;
                        height: 46px;
                        background-image: url("../../assets/images/xiazai.png");
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        color: white;
                        font-size: 20px;
                        text-align: center;
                        line-height: 46px;
                        margin-top: 160px;
                        cursor: pointer;
                    }
                }
            }

        }
    }
}

::v-deep .el-breadcrumb__inner
{
    color: #969696 !important;
    font-weight: normal !important;
}



::v-deep .el-image__preview
{
    cursor: zoom-in !important;
}



._content
{
    @include respondTo('phone')
    {
        margin-top: 80px;

        ._myt
        {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: inline-block;
            width: 120px;
        }

        ._auto
        {
            width: 95% !important;
            padding: 0;

            ._nr
            {
                margin-top: 20px;

                ._cont
                {
                    display: block;

                    ._left
                    {
                        width: 100%;
                    }

                    ._right
                    {
                        margin-left: 0;
                        margin-top: 20px;

                        ._h4
                        {
                            font-size: 24px;
                        }

                        p
                        {
                            margin-top: 15px;
                            font-size: 18px;
                        }

                        ._js
                        {
                            font-size: 16px;
                        }

                        ._btn
                        {
                            margin: 0 auto;
                            margin-top: 30px;
                        }
                    }
                }
            }

        }
    }
}


</style>


<style>
@media (min-width:320px) and (max-width:480px) {
    .message-logout {
        width: 90%;
    }
}
.el-image-viewer__wrapper {
  z-index: 9999 !important;
}
</style>